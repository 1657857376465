import { computed } from 'vue';

export function useItemDto(item) {
    const id = computed(() => {
        if (item && item.id && item.id.length > 0) {
            return item.id;
        }
        return '';
    });

    const sourceType = computed(() => {
        if (item && item.sourceType && item.sourceType.length > 0) {
            return item.sourceType;
        }
        return '';
    });

    const primaryImageUrl = computed(() => {
        let url = '/images/no-image.jpg';
        if (item && item.images && item.images.length > 0) {
            url = item.images[0].url ? item.images[0].url : item.images[0] ;

        } else if (item && item.image && item.image != '') {

            url = item.image;

        } else if (item && item.attachments && item.attachments.length > 0) {
            url = item.attachments[0].url ? item.attachments[0].url : item.attachments[0] ;
        }

        return url;
    });

    const primaryImages = computed(() => {
        let url = ['/images/no-image.jpg'];
        if (item && item.images && item.images.length > 0) {
            if (item.images.length > 1) url = [item.images[0].url, item.images[1].url];
            else url = [item.images[0].url];
        } else if (item && item.attachments && item.attachments.length > 0) {
            if (item.attachments.length > 1) url = [item.attachments[0].url, item.attachments[1].url];
            else url = [item.attachments[0].url];
        }

        return url;
    });

    const title = computed(() => {
        if (item && item.title) {
            return item.title;
        }
        return '';
    });

    const model = computed(() => {
        if (item && item.model) {
            return item.model;
        }
        return '';
    });

    const price = computed(() => {
        if (sourceType.value == 'bundle') {
            // console.log( 'bundle');
            if (item.skuDetails?.length) {
                // console.log( 'bundlescac ~~~');
                // console.log(item.skuDetails.reduce((sum, sku) => sum + sku.salesPrice, 0));
                return item.skuDetails.reduce((sum, sku) => sum + sku.salesPrice, 0);
            }
        }

        if(item && item.minPrice) {

            return item.minPrice + '';
        } else if (item.skus && item.skus.length > 0) {
        // if (item && item.tags && item.tags.length > 0) {

                // if (item.tags[0] == 'FINISHED_GOODS')
                return item.skus[0].salesPrice + '';
            // }

            // if (item.tags[0] == 'FINISHED_GOODS') return item.salesPrice + '';
            
            // if (item && item.fee) return item.fee + '';

        } else if(item && item.price) {

            return item.price;
        } else if(item && item.fee) {

            return item.fee + '';
        } else if(item && item.minFee) {
            return item.minFee;
        }
        return '';
    });

    const discountedSalesPrice = computed(() => {
        // if (item && item.tags && item.tags.length > 0) {

        if (item.skus && item.skus.length > 0) {

                // if (item.tags[0] == 'FINISHED_GOODS')
                return item.skus[0].discountedSalesPrice + '';
            // }
            // if (item.tags[0] == 'FINISHED_GOODS') return item.discountedSalesPrice + '';
        } else if(item && item.discountedSalesPrice) {

            return item.discountedSalesPrice;
        } 
        return 0;
    });

    const subscriptionPrice = computed(() => {
        if (item && item.tags && item.tags.length > 0) {
            if (item && item.length > 0) {
                if (item.tags[0] == 'FINISHED_GOODS')
                    return item.subscribedPrice + '';
            }
            if (item.tags[0] == 'FINISHED_GOODS')
                return item.subscribedPrice + '';
        }
        return '';
    });


    return {
        id,
        sourceType,
        primaryImageUrl,
        primaryImages,
        title,
        model,
        price,
        discountedSalesPrice,
        subscriptionPrice,
    };
}
